<template>
  <div class="container">
    <header-back name='提现记录'></header-back>
    <section class="top44 rec-cen">
      <van-pull-refresh
        v-model="refreshing"
        style="min-height:100vh"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="with-c">

            <van-cell
              v-for="(item,index) in cardList"
              :key='index'
            >
              <div
                slot="title"
                class="custom-title"
              >
                <van-icon
                  name="balance-list"
                  size='0.6rem'
                  color="#FF7E00 "
                /> &nbsp;银行卡提现{{item.bankNum | getBankNum}} ({{item.type}})
              </div>
              <div slot="right-icon">
                <span class="with-span">￥{{item.amount}}</span>
              </div>
              <div slot="label">
                <div class="lable1">{{item.addTime}}</div>
                <div class="lable2">{{item.status | getWithdrawState}}</div>
              </div>
            </van-cell>

          </div>
        </van-list>
      </van-pull-refresh>
    </section>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import API from "../../api/http";
export default {
  components: { HeaderBack },
  data() {
    return {
      cardList: [],
      userShow: false,
      listQuery: {
        page: 1,
      },
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  filters: {
    getBankNum(val) {
      let len = val.length;
      return "****" + val.substring(len - 4);
    },
  },
  created() {},

  methods: {
    onLoad() {
      if (this.refreshing) {
        this.cardList = [];
        this.refreshing = false;
      }
      console.log("onLoad");
      this.loading = true;
      this.getList();
    },
    onRefresh() {
      //下拉刷星
      // 清空列表数据

      this.finished = false;
      this.refreshing = true;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.listQuery.page = 1;
      this.cardList = [];
      this.getList(1);
    },
    getList(val) {
      API.getWithdrawHis(this.listQuery)
        .then((res) => {
          let data = res.data;
          this.loading = false;

          this.seachNum = data.total;

          if (data.list.length > 0) {
            let list = data.list.map((item) => {
              return {
                ...item,
                type: item.type ? "对公" : "对私",
              };
            });

            if (data.pages === 1) {
              this.finished = true;
            }

            if (val) {
              this.refreshing = false;

              this.cardList = list;
            } else {
              this.cardList = this.cardList.concat(list);
            }

            this.listQuery.page++;
          } else {
            this.refreshing = false;
            this.finished = true;
          }
        })
        .catch(() => {
          this.refreshing = false;
          this.loading = false;
          this.finished = true;
        });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.rec-cen {
  padding-left: 12px;
  padding-right: 12px;
}
.with-c {
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  background: #fff;
  margin-top: 16px;

  .van-cell {
    padding: 10px 16px;
    align-items: center;
    .van-icon {
      vertical-align: middle;
    }
    .van-cell__label {
      padding-left: 0.7rem;
    }
  }
  .lable2 {
    color: #70c242;
  }
}

.with-c .van-cell .van-cell__value {
  // text-align: left;
  color: $textColor;
}
.with-c {
  .van-cell-group__title {
    font-weight: 500;
    color: #474747;
  }
  .van-cell > .van-field__label {
    width: 1em;
  }
  .with-c-t {
    border-top: 1px solid #ebedf0;
    font-size: 12px;
  }
  .van-cell-group > .van-cell {
    padding: 16px;
    .van-cell__label {
      padding-left: 0.7rem;
    }
  }
}
.with-span {
  color: $textColor;
  cursor: pointer;
  margin-left: 10px;
  font-weight: 500;
}
</style>
